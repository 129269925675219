import React from 'react'
import { Button, Box, LinearProgress, Typography } from '@mui/material'
import { TestContext } from '../layouts/TestLayout'
import { useParams } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import AppContext from '../context/AppContext'
import useIsMobile from '../hooks/useIsMobile'
import { BottomBox } from '../components/IntroductionParts'
import { styled } from '@mui/material/styles'
import { ReactComponent as Logo } from '../assets/logo-nhlstenden.svg'

const StyledNextButton = styled(Button)(({ theme }) => ({
  paddingLeft: '18px',
  paddingRight: '18px',
  '&.Mui-disabled': {
    backgroundColor: '#ccdeed',
    color: '#fff'
  }
}))

const TestProgress = ({ currentProgress, progressLabel }) => {
  const isMobile = useIsMobile()
  return <Box
    mt={isMobile ? 1 : 2}
    mb={isMobile ? 0 : 1}
    display='flex'
    flexDirection='row'
    alignItems='center'
  >
    <Box
      width='100%'
      mr={2}
    >
      <LinearProgress
        value={currentProgress}
        variant="determinate"
        color='secondary'
        sx={{
          height: '8px',
          borderRadius: '100px'
        }}
      />
    </Box>
    <Box>
      <Typography variant='subtitle2' color='primary.main'>
        {progressLabel}
      </Typography>
    </Box>
  </Box>
}

const MobileTestNav = ({
  goToNext,
  disableNext,
  nextLabel,
  children
}) => {
  return <Box>
    <Box spacing={4} direction='row' display='flex'>
      <Box
        flexBasis='100%'
      >
        <StyledNextButton
          variant='contained'
          color='primary'
          disableElevation
          disabled={disableNext}
          size='small'
          fullWidth
          endIcon={<ArrowForwardRoundedIcon />}
          onClick={() => goToNext(1)}
        >
          {
            nextLabel
          }
        </StyledNextButton>
      </Box>
    </Box>
    {children}
  </Box>
}

const DesktopTestNav = ({
  goToNext,
  disableNext,
  nextLabel,
  children
}) => {
  const ButtonRef = React.useRef()

  return <Box
    display='flex'
    justifyContent='space-evenly'
    alignItems='center'
    pt={1}
    pb={1}
  >
    <Box width='200px'>
      <Logo width='60px' height='60px' />
    </Box>
    <Box sx={{ maxWidth: '460px', width: '100%' }}>
      {children}
    </Box>
    <Box width='200px'>
      <StyledNextButton
        ref={ButtonRef}
        variant='contained'
        color='primary'
        disableElevation
        disabled={disableNext}
        // disabled
        sx={{ zIndex: 4 }}
        size='small'
        endIcon={<ArrowForwardRoundedIcon />}
        onClick={() => goToNext(1)}
      >
        {
          nextLabel
        }
      </StyledNextButton>
    </Box>
  </Box>
}

const TestNavigation = () => {
  const {
    questions,
    activeStep,
    setActiveStep,
    goToNext,
    goToPrevious,
    activeQuestion,
    currentAnswers
  } = React.useContext(TestContext)

  const { topTen } = React.useContext(AppContext)
  const matches = useIsMobile()
  const params = useParams()
  const isFirstStep = activeStep === 0
  const isLastStep = ((activeStep + 1) === questions.length)
  const currentProgress = ((activeStep + 1) / questions.length) * 100

  const currentQuestionAnswers = currentAnswers.find(questions => questions.questionId === activeQuestion.id)

  const isTopTen = params?.nextRoute === 'select-activities'

  const disabledNext = () => {
    if (!currentQuestionAnswers || currentQuestionAnswers?.answers?.length === 0) {
      return true
    }
    if (isTopTen && topTen.length < 5) {
      return true
    }
    return false
  }

  return <BottomBox>
    {
      matches
        ? <MobileTestNav
            goToNext={goToNext}
            goToPrevious={goToPrevious}
            disableNext={disabledNext()}
            nextLabel={
              isLastStep
                ? 'Bekijk testuitslag'
                : 'Volgende'
            }
            isFirstStep={isFirstStep}
          >
            {!isTopTen && <TestProgress
              currentProgress={currentProgress}
              progressLabel={`${activeStep + 1}/${questions.length}`}
            />}
            
          </MobileTestNav>
        : <DesktopTestNav
          goToNext={goToNext}
          goToPrevious={goToPrevious}
          disableNext={disabledNext()}
          isFirstStep={isFirstStep}
          nextLabel={
            isLastStep
              ? 'Bekijk testuitslag'
              : 'Volgende'
          }
        >
        <Box width='100%'>
          {
            !isTopTen && <TestProgress
              currentProgress={currentProgress}
              progressLabel={`${activeStep + 1}/${questions.length}`}
            />
          }
          
        </Box>
      </DesktopTestNav>
    }
    {
      process.env.NODE_ENV !== 'production' && <Button onClick={() => setActiveStep([(questions?.length - 1), 0])}>Stiekem naar de laatste</Button>
    }
  </BottomBox>
}

export default TestNavigation
