import React from 'react'
import usePageTracking from '../hooks/usePageTracking'

export const AnalyticsContext = React.createContext({
  trackEvent: () => {}
})

export const AnalyticsContextProvider = ({
  children
}) => {
  const trackEvent = usePageTracking()

  return <AnalyticsContext.Provider
    value={{
      trackEvent: trackEvent
    }}
  >
    { children }
  </AnalyticsContext.Provider>
}

