import { Suspense, lazy } from 'react'
import LoadingScreen from './components/LoadingScreen'
import { Navigate } from 'react-router-dom'

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)

const IntroductionFirstStep = Loadable(lazy(() => import('./pages/IntroductionFirstStep')))
const IntroductionSecondStep = Loadable(lazy(() => import('./pages/IntroductionSecondStep')))
const IntroductionThirdStep = Loadable(lazy(() => import('./pages/IntroductionThirdStep')))
const IntroductionLayout = Loadable(lazy(() => import('./layouts/IntroductionLayout')))

const TestLayout = Loadable(lazy(() => import('./layouts/TestLayout')))
const TestQuestion = Loadable(lazy(() => import('./pages/TestQuestion')))

const SelectTopTen = Loadable(lazy(() => import('./pages/SelectTopTen')))

const TestResults = Loadable(lazy(() => import('./pages/TestResults')))
const TestResultsLayout = Loadable(lazy(() => import('./layouts/TestResultsLayout')))

const RequestLink = Loadable(lazy(() => import('./pages/RequestLink')))
const RequestLinkLayout = Loadable(lazy(() => import('./layouts/RequestLinkLayout')))

const Thanks = Loadable(lazy(() => import('./pages/Thanks')))

const FullResultsLayout = Loadable(lazy(() => import('./layouts/FullResultsLayout')))
const TestResultsFull = Loadable(lazy(() => import('./pages/TestResultsFull')))

const TestResultsFullID = Loadable(lazy(() => import('./pages/TestResultsFullID')))

const TestResultsTips = Loadable(lazy(() => import('./pages/TestResultsTips')))

const Routes = [
  {
    path: 'introduction',
    element: <IntroductionLayout />,
    children: [
      {
        path: '',
        element: <IntroductionFirstStep />
      },
      {
        path: 'activities',
        element: <IntroductionSecondStep />
      },
      {
        path: 'results',
        element: <IntroductionThirdStep />
      }
    ]
  },
  {
    path: 'test',
    element: <TestLayout />,
    children: [
      {
        path: ':nextRoute',
        element: <SelectTopTen />
      },
      {
        path: '',
        element: <TestQuestion />
      }
    ]
  },
  {
    path: 'results',
    element: <TestResultsLayout />,
    children: [
      {
        path: '',
        element: <TestResults />
      }
    ]
  },
  {
    path: 'full-results',
    element: <FullResultsLayout />,
    children: [
      {
        path: '',
        element: <TestResultsFull />
      },
      {
        path: ':uniqueId',
        element: <TestResultsFullID />
      },
      {
        path: 'test-results-tips',
        element: <TestResultsTips />
      }
    ]
  },
  {
    path: 'request-link',
    element: <RequestLinkLayout />,
    children: [
      {
        path: '',
        element: <RequestLink />
      }
    ]
  },
  {
    path: 'thanks',
    element: <Thanks />
  },
  {
    path: '*',
    element: <Navigate
      to='/introduction'
      replace
    />
  }
]

export default Routes
