import React from 'react'
import { Dialog, Box, Typography, Button } from '@mui/material'
import Slide from '@mui/material/Slide';
import HelpIcon from '@mui/icons-material/Help';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const TooltipDialog = ({ open, setOpenDialog, content }) => {
  return <Dialog
    open={open}
    onClose={() => setOpenDialog([false, null])}
    TransitionComponent={Transition}
  >
    <Box>
      <Box
        p={4}
      >
        <HelpIcon color='secondary' sx={{ fontSize: '40px' }} />
        <Typography variant='h6' fontWeight='bold' sx={{ mt: 2, mb: 2 }}>
          Uitleg
        </Typography>
        <Typography variant='subtitle2'>
          {content}
        </Typography>
        <Box
          display='flex'
          justifyContent='flex-end'
        >
          <Button
            onClick={() => setOpenDialog([false, null])}
          >
            Begrepen
          </Button>
        </Box>
      </Box>
    </Box>
  </Dialog>
}

export default TooltipDialog
