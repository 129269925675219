import React from 'react'
import { useQuery } from 'react-query'
import HelpDialog from '../components/HelpDialog'
import ToolTipDialog from '../components/TooltipDialog'
import { getQuestions } from '../layouts/TestLayout'

const AppContext = React.createContext({
  choices: [],
  topTen: [],
  date: new Date()
})


export const AppContextProvider = ({ children }) => {
  const [topTen, setTopTen] = React.useState([])
  const [showHelp, setShowHelp] = React.useState(false)
  const [results, setResults] = React.useState([])
  const [answers, setCurrentAnswers] = React.useState([])
  const [[openTooltip, tooltipContent], setOpenTooltip] = React.useState([false, null])
  const { data: dataServer, isLoading } = useQuery('questions', getQuestions, {
    refetchOnWindowFocus: false
  })

  const resetTest = () => {
    setTopTen([])
    setCurrentAnswers([])
  }

  const data = dataServer?.data
  const questions = data?.questions
  const categories = data?.categories
  const glossary = data?.glossary

  const texts = {
    introContent: data?.introContent,
    introTitle: data?.introTitle,
    resultsIntrotext: data?.resultsIntrotext,
    resultsDoc: data?.resultsDoc,
    afterResultsIntrotext: data?.afterResultsIntrotext,
    afterResultsTitle: data?.afterResultsTitle
  }

  return <AppContext.Provider
    value={{
      topTen,
      setTopTen,
      showHelp,
      setShowHelp,
      categories,
      questions,
      answers,
      setCurrentAnswers,
      resetTest,
      isLoading,
      texts,
      setResults,
      results,
      glossary,
      setOpenTooltip
    }}
  >
    {children}
    <HelpDialog
      open={showHelp}
      setOpenDialog={setShowHelp}
    />
    <ToolTipDialog
      open={openTooltip}
      setOpenDialog={setOpenTooltip}
      content={tooltipContent}
    />
  </AppContext.Provider>
}



export const AppConsumer = AppContext.Consumer
export default AppContext
