import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize('UA-214547289-1', {
        siteSpeedSampleRate: 100
      })
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [initialized, location]);

  const trackEvent = (newEvent) => {
    if (initialized) {
      ReactGA.event(newEvent)
    }
  }

  return trackEvent
};

export default usePageTracking;
