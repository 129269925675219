import React from 'react'
import { Box, Container } from '@mui/material'
import { styled } from '@mui/system';
import useIsMobile from '../hooks/useIsMobile'

const BottomBoxContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile'
})(( {isMobile} ) => ({
  backgroundColor: '#fff',
  borderRadius: '0',
  boxShadow: '0px -3px 20px rgba(7, 90, 167, 0.1)',
  textAlign: 'center',
  // position: 'relative',
  position: 'fixed',
  bottom: 0,
  zIndex: 2,
  width: '100%',
  // zIndex: 2,
  ...!isMobile && ({
    borderRadius: 0
  })
}))

export const BottomBox = ({ children }) => {
  const isMobile = useIsMobile()
  return <BottomBoxContainer isMobile={isMobile}>
    <Box
      px={isMobile ? 1 : 2}
      pb={isMobile ? 1 : 0}
      mt={isMobile ? 2 : 0}
    >
      {children}
    </Box>
  </BottomBoxContainer>
}

const BottomBoxContainerResults = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile'
})(( {isMobile} ) => ({
  backgroundColor: '#fff',
  borderRadius: '0',
  boxShadow: '0px -3px 20px rgba(7, 90, 167, 0.1)',
  textAlign: 'center',
  position: 'fixed',
  bottom: '0',
  zIndex: 2,
  width: '100%',
  // zIndex: 2,
  ...!isMobile && ({
    borderRadius: 0
  })
}))

export const BottomBoxResults = ({ children }) => {
  const isMobile = useIsMobile()
  return <BottomBoxContainerResults isMobile={isMobile}>
    <Box
      px={isMobile ? 0 : 2}
      py={3}
    >
      {children}
    </Box>
  </BottomBoxContainerResults>
}


export const Header = ({ children }) => {
  return <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    flexDirection='column'
    sx={{
      height: 'calc(100% - 300px)',
      marginTop: '50px'
    }}
  >
    {children}
  </Box>
}

export const ButtonBox = ({ children }) => {
  return <Box
    mt={5}
    display='flex'
  >
    {children}
  </Box>
}

export const ContentBox = ({ children }) => {
  return <Container maxWidth='md'>
    <Box
      width='100%'
      display='flex'
      justifyContent='center'
    >
      <Box
        position='relative'
        sx={{
          height: '100%',
          // width: isMobile ? 'auto': '460px'
        }}
      >
        {children}
      </Box>
    </Box>
  </Container>
}

