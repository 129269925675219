import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: ['Cera Pro', 'sans-serif'].join(',')
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          textTransform: 'inherit',
          height: '52px',
          fontWeight: 'bold',
          fontSize: '16px',
          boxShadow: 'none'
        },
        sizeSmall: {
          height: '39px',
          fontSize: '15px'
        }
      }
    },
    MuiButtonGroup: {
      root: {
        height: '39px',
      }
    }
  },
  palette: {
    common: {
      black: '#424445'
    },
    primary: {
      main: '#005AA7',
      light: '#e2edf5',
      dark: '#00294B'
    },
    secondary: {
      main: '#02A9AD',
      light: '#d9f2f3'
    }
  }
})

export default responsiveFontSizes(theme)
