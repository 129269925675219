function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const getUniqueAnswers = (answers) => {
  const activitiesFromAnswers = answers
    .map(question => question.answers)
    .map(answer => {
      return answer.map(answerDetail => {
        return {
          title: answerDetail.title,
          id: answerDetail.id
        }
      })
    })
    .flat()

  const uniqueActivities = getUniqueListBy(activitiesFromAnswers, 'title')

  return uniqueActivities
}

const sortByScore = (a, b) => {
  if (a.score > b.score) {
    return -1
  }
  if (a.score < b.score) {
    return 1
  }
  return 0
}

const sortByPercentage = (a, b) => {
  if (a.percentage > b.percentage) {
    return -1
  }
  if (a.percentage < b.percentage) {
    return 1
  }
  return 0
}

export const getImageFromQuestion = (activityId, questions) =>  {
  const scoreId = activityId.substring(2)

  const theQuestion = questions.find(question => {
    const choiceIds = question.choices.map(choice => choice.id)
    return choiceIds.includes(scoreId)
  })

  return theQuestion?.image ?? ''
}

// does all the conversion work combining questions, answers, scores
export const getResultsFromAnswers = (categories, scores, choiceCats, questions) => {
  if (!scores || !categories) return {}

  const reduceScores = (arr) => arr.reduce((initial, next) => initial + Number(next.score), 0)

  // const getImageFromQuestion = (activityId) =>  {
  //   const scoreId = activityId.substring(2)

  //   const theQuestion = questions.find(question => {
  //     const choiceIds = question.choices.map(choice => choice.id)
  //     return choiceIds.includes(scoreId)
  //   })

  //   return theQuestion?.image ?? ''
  // }

  const flatCategories = Object.keys(categories)
    .map(cat => {
      return categories[cat]
    })
    .flat()

  const mapScoresWithCategories = scores.map(score => {
    const foundCategorie = flatCategories.find(flatCat => flatCat.id == score.id)

    const coursesForScores = choiceCats?.[score.id.substring(2)]?.courses

    return {
      ...foundCategorie,
      score: score.score,
      percentage: score.percentage || 0,
      ranking: score.ranking,
      courses: coursesForScores || []
    }
  })

  const activities = mapScoresWithCategories
    .filter(scores => scores.type === 'activities')
    .map(scores => ({
      ...scores,
      imageFromQuestion: getImageFromQuestion(scores.id, questions)
    }))
    .sort(sortByScore)

  const personas = mapScoresWithCategories
    .filter(scores => scores.type === 'personas')
    .sort(sortByPercentage)

  const sectors = mapScoresWithCategories
    .filter(scores => scores.type === 'sectors')
    .sort(sortByPercentage)

  return {
    all: mapScoresWithCategories,
    personas,
    activities,
    sectors,
    totalScores: {
      personas: reduceScores(personas),
      activities: reduceScores(activities),
      sectors: reduceScores(sectors)
    }
  }
}
