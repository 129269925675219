import React from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import Loader from '../components/Loader'
import TestNavigation from '../components/TestNavigation'
import { AnalyticsContext } from '../context/AnalyticsContext'
import TestHeader from '../components/TestHeader'
import { ContentBox } from '../components/IntroductionParts'
import { getUniqueAnswers } from '../helpers/TestHelper'
import axios from 'axios'
import BackgroundArrow from '../assets/chevron-large.svg'
import { BASE_URL } from '../config'
import useIsMobile from '../hooks/useIsMobile'
import AppContext from '../context/AppContext'
import { useMutation } from 'react-query'

export const getQuestions = async () => {
  const response = await axios.get(`${BASE_URL}/survey/index/questions`)
  return response
}

export const TestContext = React.createContext()

const postRanking = async ({ ranking, id }) => {
  const response = await axios.post(`${BASE_URL}/survey/index/rank-cats/key/${id}`, { ranking })
  return response
}

const TestLayout = () => {
  const { trackEvent } = React.useContext(AnalyticsContext)
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const params = useParams()

  const isSelectTopTen = params?.nextRoute === 'select-activities'

  const {
    answers: currentAnswers,
    setCurrentAnswers,
    questions,
    isLoading,
    topTen,
    results,
    resetTest
  } = React.useContext(AppContext)

  const { isLoading: isLoadingRankingPost, mutateAsync } = useMutation(postRanking)

  const [[activeStep, direction], setActiveStep] = React.useState([0, 0])

  const showExit = (event) => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
      e.returnValue = '';
    }
    return '';
  }

  React.useEffect(() => {
    window.addEventListener('beforeunload', showExit)
    return () => window.removeEventListener('beforeunload', showExit)
  }, [])

  React.useEffect(() => {
    if (isSelectTopTen) {
      setActiveStep([(questions?.length - 1), 0])
    }
  }, [isSelectTopTen, questions])

  const goToNext = async (direction) => {
    const isLastStep = (activeStep + 1) === questions.length

    if (isLastStep) {
      trackEvent({
        category: 'events',
        action: 'test_complete',
        label: 'questions'
       })

      if (params?.nextRoute === 'select-activities') {
        mutateAsync({
          ranking: topTen,
          id: results?.data?.unique_key
        })
        navigate('/results')
      } else {
        // if we 10 or less unique answers, we can skip the select top 10.
        const uniquerAnswers = getUniqueAnswers(currentAnswers)

        if (uniquerAnswers.length > 5) {
          navigate('/test/select-activities')
        } else {
          navigate('/results', {
            state: {
              answers: currentAnswers
            }
          })
        }
      }
    } else {
      trackEvent({
        category: 'events',
        action: `view_question_${Number(activeStep + 1)}`,
        label: 'questions'
      })
      setActiveStep((currentStep) => [currentStep[0] + 1, direction])
    }
  }

  const goToPrevious = (direction) => {
    if (activeStep === 0) {
      // setActiveStep([0, 0])
      resetTest()
      navigate('/introduction')
    } else {
      setActiveStep((currentStep) => {
        return [currentStep[0] - 1, direction]
      })
    }
  }

  const getActiveQuestion = () => {
    return questions?.[activeStep]
  }

  if (isLoading || isLoadingRankingPost) {
    return <Loader />
  }

  return <TestContext.Provider
    value={{
      questions: questions || [],
      activeQuestion: getActiveQuestion(),
      setActiveStep,
      activeStep,
      goToNext,
      goToPrevious,
      currentAnswers,
      setCurrentAnswers,
      direction
    }}
  >

    <Box
      backgroundColor='primary.light'
      position='absolute'
      left='0'
      right='0'
      top='0'
      sx={{
        height: isMobile ? '100%' : !isSelectTopTen ? '100vh' : '100%'
      }}
    >
      <Box
        position='absolute'
        // top='-188px'
        width='100%'
        sx={{
          height: '100%',
          backgroundImage: `url(${BackgroundArrow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
          // paddingBottom: isSelectTopTen && '70px'
        }}
      />
      <Box
        position='fixed'
        top={0}
        zIndex={2}
        width='100%'
      >
        <TestHeader goBack={goToPrevious} showBack={!isSelectTopTen} showScrollBg={false} />
      </Box>
      <Box
        pt={ isMobile ? 0 : '14px' }
        overflow='hidden'
        pb={
          isMobile
            ? isSelectTopTen
              ? '100px'
              : '100px'
            : '100px'
        }
        backgroundColor='#e2edf5'
      >
        {
          isMobile
            ? <>
            <Box
              sx={{
                width: '100%'
              }}
            >
              <Outlet />
            </Box>
            <TestNavigation />
          </>
          : <>
            <ContentBox>
              <Box
                sx={{
                  width: '100%'
                }}
              >
                <Outlet />
              </Box>
            </ContentBox>
            <TestNavigation />
          </>
        }
      </Box>
    </Box>
  </TestContext.Provider>
}

export default TestLayout
