import React from 'react'

import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import PaletteIcon from '@mui/icons-material/Palette';
import { TreeView, TreeItem } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Table, TableRow, TableCell } from '@mui/material';

function isObject(val) {
  if (val === null) { return false;}
  return ( (typeof val === 'function') || (typeof val === 'object') );
}

const collectItems = (theme, handleTreeClick) => {
  if (theme) {

    let counter = 0
    return Object.keys(theme).map((key, index) => {
      const hasObjectChildren = isObject(theme[key])

      if (hasObjectChildren) {
        return <TreeItem nodeId={`${counter}`} label={key} key={counter}>

          {
            Object.keys(theme[key]).map((objChild, indexChild) => {
              counter++
              return <TreeItem
                key={counter}
                nodeId={`${counter}`}
                label={objChild}
                onClick={() => handleTreeClick(key, objChild, indexChild)}
              />
            })
          }
        </TreeItem>
      }
      return <TreeItem nodeId={`${index}`} label={key} key={counter} />
    })
  }
}

const MuiThemeInspector = () => {
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const [currentView, setCurrentView] = React.useState(null)

  const handleTreeClick = (parent, objProperty, index) => {
    const clickedId = theme[parent][objProperty]
    setCurrentView(clickedId)
  }

  const createThemeTree = React.useCallback(() => {
    return collectItems(theme, handleTreeClick)
  }, [theme])

  return <>
      <Tooltip
        title='Theme settings'
      >
        <Fab
          style={{
            position: 'fixed',
            bottom: 10,
            left: 10,
            zIndex: 2000
          }}
          color="primary"
          onClick={() => setOpen(true)}
        >
        <PaletteIcon />
      </Fab>
    </Tooltip>

    <Dialog
      onClose={() => setOpen(false)}
      open={open}
      sx={{
        minWidth: '50vw'
      }}
    >
      <DialogTitle>Theme inspector</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: '100%',
            minHeight: 600
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                {
                  createThemeTree()
                }
              </TreeView>
            </Grid>
            <Grid item xs={8}>
              <Box>
                Theme inspector
                {
                  currentView && isObject(currentView) && Object.keys(currentView).length > 0 && <Table>
                    {
                      Object.keys(currentView).map((key, index) => <TableRow key={index}>
                        <TableCell>{key}</TableCell>
                        <TableCell style={{ backgroundColor: currentView[key] }}>{currentView[key]}</TableCell>
                      </TableRow>)
                    }
                  </Table>
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  </>
}


export default MuiThemeInspector
