import React from 'react'
import { Box, Button, Typography, Dialog } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import useIsMobile from '../hooks/useIsMobile';

import useDetectScroll from '../hooks/useDetectScroll';

const TestHeader = ({
  goBack,
  showBack = true,
  showTop = false,
  showScrollBg = true
}) => {
  const [showHelp, setShowHelp] = React.useState(false) 
  const isMobile = useIsMobile()
  const scrolled = useDetectScroll()

  if (isMobile) {
    return <Box>
      {
        showBack && <Button
          sx={{
            backgroundColor: 'rgba(0, 90, 167, .7)',
            minWidth: '48px',
            height: '48px',
            alignItems: 'center',
            position: 'absolute',
            top: 10,
            left: isMobile ? 10 : 20,
            '> span': {
              marginRight: 0,
              marginLeft: 0
            }
          }}
          onClick={goBack}
          startIcon={<ArrowBackRoundedIcon sx={{ color: 'white' }} />}
          color='primary'
        />
      }
    </Box>
  }

  return <>
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='76px'
      px={2}
      backgroundColor={((scrolled || isMobile) && showScrollBg) && 'white' }
      position='relative'
      zIndex={2}
      sx={{
        boxShadow: isMobile ? '0 4px 6px #075AA726' : 'none'
      }}
    >
      {
        showBack && <Button
          sx={{
            backgroundColor: 'rgba(0, 90, 167, .7)',
            minWidth: '48px',
            height: '48px',
            alignItems: 'center',
            position: 'absolute',
            left: isMobile ? 10 : 20,
            '> span': {
              marginRight: 0,
              marginLeft: 0
            }
          }}
          onClick={goBack}
          startIcon={<ArrowBackRoundedIcon sx={{ color: 'white' }} />}
          color='primary'
        />
      }
      {showTop && <Typography variant='h5' fontWeight='bold' color='primary.main'>Studiekeuzetest</Typography>}
      
    </Box>
    <Dialog
      open={showHelp}
      onClose={() => setShowHelp(false)}
    >
      <Box
        p={4}
      >
        <Typography variant='h6' fontWeight='bold'>Uitleg bij de Studiekeuzetest</Typography>
        <Typography variant='subtitle2'>Bij elke foto staan 4 activiteiten genoemd die je in deze omgeving zou kunnen uitvoeren. </Typography>
      </Box>
    </Dialog>
  </>
}

export default TestHeader
