import React from 'react'

const useDetectScroll = () => {
  const [scrolled, setScrolled] = React.useState(0)

  const handleScrollEvent = (e) => {
    const scrolledAmount = e.currentTarget.scrollY

    // prevent a bazillion of re-renders for each scroll number
    if (scrolled === 0 && scrolledAmount > 0) {
      setScrolled(scrolledAmount)
    }

    if (scrolledAmount === 0) {
      setScrolled(scrolledAmount)
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent)

    return () => window.removeEventListener('scroll', handleScrollEvent)
  }, [])

  return !!scrolled
}

export default useDetectScroll